

:root {
  --font-family: 'Quicksand', sans-serif;
  --font-size: 14px;
  /* --font-color: rgba(0, 0, 0, 0.87); */
  --label-font-size: 10px;

  --default-border: 1px solid rgba(0, 0, 0, 0.12);
  /* --shadow-color: rgba(0, 0, 0, 0.87); */
  --default-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  /* --content-background-color: rgb(255, 255, 255); */

  --padding-0: 6px;
  --padding-1: 12px;
  --margin-0: 6px;
  --margin-1: 12px;

  /* --primary-color: rgb(44, 45, 49); */
  /* --primary-light-color: rgb(191, 192, 193); */
  /* --primary-contrast-color: rgb(255, 255, 255); */


  /* --secondary-color: rgb(51, 93, 127); */
  /* --secondary-contrast-color: rgb(255, 255, 255); */

  /* --app-default-background-color: rgb(247, 247, 247); */

  /* Notifications */
  /* --info-color: rgb(47, 27, 207);
  --error-color: rgb(175, 0, 0);
  --warning-color: rgb(255, 126, 0);
  --success-color: rgb(0, 131, 21); */

  /* Hotkeys */
  --hotkey-shadow: var(--default-box-shadow);

  /* Navigation */
  --notification-width: 300px;
  /* --notification-color: var(--primary-color); */
  /* --notification-contrast-color: var(--primary-contrast-color); */
  --notification-shadow: var(--default-box-shadow);
  /* --notification-close-button-color: rgba(var(--notification-contrast-rgb-color), 0.3); */
  /* --notification-close-button-hover-color: var(--notification-contrast-color); */
  --navigation-alert-transition-duration: 0.7s;

  /* Grid */
  /* --grid-row-active-preview-color: rgba(var(--secondary-rgb-color), 0.1); */

  /* Top navigation */
  /* --top-navigation-height: 37px; */
  /* --top-navigation-color: var(--primary-color); */
  /* --top-navigation-contrast-color: var(--primary-contrast-color); */
  /* --top-navigation-close-hover-color: var(--error-color); */
  --top-navigation-tab-transition: background 0.5s;
  --top-navigation-tab-hover-color: var(--secondary-color);
  --top-navigation-link-hover-color: var(--top-navigation-tab-hover-color);

  /* Side navigation */
  /* --side-navigation-color: var(--primary-color); */
  /* --side-navigation-shadow-color: var(--shadow-color); */
  /* --side-navigation-contrast-color: var(--primary-contrast-color); */
  --side-navigation-module-hover-color: var(--secondary-color);
  --side-navigation-tab-transition: background 0.5s;
  --side-navigation-module-transition: var(--side-navigation-tab-transition);

  /* Inputs */
  /* --input-select-icon-color: rgba(0, 0, 0, 0.2); */
  /* --input-select-icon-hovered-color: rgb(0, 0, 0); */

  /* Quill */
  /* --quill-header-color: var(--secondary-color); */
  --quill-blockquote-border-left: 4px solid rgb(0, 0, 0, 0.87);

  /* Toolbar */
  /* --toolbar-color: var(--secondary-color); */
  /* --toolbar-contrast-color: var(--secondary-contrast-color); */
  /* --toolbar-contrast-placeholder-color: rgba(var(--toolbar-contrast-rgb-color), 0.3); */
  /* --toolbar-menu-item-color: rgba(var(--secondary-contrast-color), 0.7); */
  /* --toolbar-menu-item-hover-color: var(--secondary-contrast-color); */

  /* Button */
  /* --button-color: var(--secondary-color); */

  /* Checkbox */
  /* --checkbox-color: var(--secondary-color); */
  /* --checkbox-ripple-color: rgba(var(--checkbox-rgb-color), 0.3); */

  /* Stepper */
  /* --stepper-color: var(--secondary-color); */

  /* Basic list */
  --base-list-preview-panel-width: 50%;
  --base-list-column-panel-width: 400px;
  --base-list-view-panel-width: 400px;
  --base-list-info-row-height: 20px;

  /* Progress bar */
  /* --progress-bar-color: var(--secondary-color);
  --progress-bar-background-color: rgb(225,225,225); */

  /* Schedule */
  --schedule-resource-border: var(--default-border);
  --schedule-row-height: 50px;
}
